// GraphQLContextで参照しているのでコンポーネント内に書くとbundleが大きくなるためここで定義

import type { CompanyPartsQueryVariables } from './components/PartPickerModal.generated';
import type { PartsQueryVariables } from './hooks/usePartsPage.generated';

export const partsCacheKeyArgs = [
  'searchField',
  'assetIds',
  'isReplenishment',
  'groupIds',
  'customFieldOptionIds',
  'ids',
] satisfies Omit<keyof PartsQueryVariables, 'limit' | 'offset'>[];

export const companyPartsCacheKeyArgs = ['searchField', 'officeIds'] satisfies Omit<
  keyof CompanyPartsQueryVariables,
  'limit' | 'offset'
>[];
